<template>
  <h1 id="calendar-heading">Event Calendar</h1>
  <FullCalendar :options="calendarOptions" />
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar'

export default {
  name: "EventCalendar.vue",
  components: { FullCalendar },
  data () {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, googleCalendarPlugin],
        initialView: 'dayGridMonth',
        googleCalendarApiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        events: {
          googleCalendarId: process.env.VUE_APP_GOOGLE_CALENDAR_ID,
          className: 'gcal-event' // an option!
        }
      }
    }
  }
}
</script>

<style scoped>
#calendar-heading{
  text-align: center;
}

</style>
